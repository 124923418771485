<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M0.105573 0.552787C0.352562 0.0588081 0.953235 -0.141416 1.44721 0.105573L6 2.38197L10.5528 0.105573C11.0468 -0.141416 11.6474 0.0588081 11.8944 0.552787C12.1414 1.04677 11.9412 1.64744 11.4472 1.89443L6 4.61803L0.552787 1.89443C0.0588081 1.64744 -0.141416 1.04677 0.105573 0.552787Z"
    fill="currentColor"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M0.105573 5.05279C0.352562 4.55881 0.953235 4.35858 1.44721 4.60557L6 6.88197L10.5528 4.60557C11.0468 4.35858 11.6474 4.55881 11.8944 5.05279C12.1414 5.54676 11.9412 6.14744 11.4472 6.39443L6 9.11803L0.552787 6.39443C0.0588081 6.14744 -0.141416 5.54676 0.105573 5.05279Z"
    fill="currentColor"
  />
</template>
